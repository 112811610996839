.max-width {
    width: auto;
}

.loader-frame {
    position: relative;
}

.separator {
    padding: 1.5em;
    height: 100px;
    border-left: 1px solid #f10707;
    margin-left: -20px;
    margin-top: 1em;
}


.loader-frame {
    position: relative;
}